




































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/types/tables';
import { getBroadcastsHistory, getCustomers } from '@/api';
import { Customer } from '@/types/customers';

@Component({name: 'BroadcastHistoryList'})
export default class BroadcastHistoryList extends Vue {
    search = '';
    headers: Header[] = [
        {text: 'Время', value: 'datetime'},
        {text: 'Текст', value: 'broadcast.message'},
        {text: 'Получатели', value: 'targets'},
        //{ text: 'Группа', value: 'broadcast.group'},
        //{ text: 'Улица', value: 'broadcast.street'},
        //{ text: 'Дом', value: 'broadcast.building'},
        {text: 'Количество получателей', value: 'clients_count'},
        {text: 'Кто создал', value: 'user'},
    ];
    // TODO type!
    broadcasts = [];
    loading = false;

    async created() {
        await this.loadBroadcastsHistory();
    }

    async loadBroadcastsHistory() {
        this.loading = true;

        try {
            const broadcasts = await getBroadcastsHistory();
            this.broadcasts = broadcasts.broadcasts_history;
        } catch (e) {
        } finally {
            this.loading = false;
        }
    }
}
